import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding: 15.625rem 10vw;

  @media (max-width: 1199px) {
    padding: 11.25rem 2.25rem 7.5rem;
  }
`

const Wrapper = styled.div``

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 5.625rem;
  font-weight: 500;
  line-height: 6.25rem;

  @media (max-width: 1199px) {
    font-size: 3.625rem;
    line-height: 4.3125rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin-top: 2.5rem;

  a {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 1.125rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark2};
        border-radius: 50%;
        position: absolute;
        top: 0.75rem;
        left: 0;
      }
    }
  }
`
